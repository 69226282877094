import { BaseModel, Field, getField } from "@/bytenest-cli/definitions";
import { graphql } from "@/gql";
import { required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

const StudentsWithLocation = graphql(`
  query StudentsWithLocation {
    me {
      availableStudents {
        id
        full_name
        location {
          id
        }
      }
    }
  }
`);

const CourseStudyClasses = graphql(`
  query StudyClassAvailable($course_id: ID!) {
    course(id: $course_id) {
      id
      studyClasses {
        id
        name
      }
    }
  }
`);

const StudyClassAvailableTeachers = graphql(`
  query StudyClassAvailableTeachers($study_class_id: ID!) {
    studyClass(id: $study_class_id) {
      id
      teachers {
        id
        name
      }
    }
  }
`);

const LocationCourses = graphql(`
  query LocationCourses($location_id: ID!) {
    location(id: $location_id) {
      id
      courses {
        id
        name
        payment_info_required
      }
    }
  }
`);

const CourseFrequencies = graphql(`
  query CourseFrequencies($course_id: ID!) {
    courseFrequencies(course_id: $course_id) {
      id
      description
      start_timestamp
    }
  }
`);

interface IProps {
  enrollment_id?: string;
  baseItem: BaseModel;
}

export const useEnrollmentFields = (props: IProps) => {
  const { t } = useI18n();

  const Students = getField({
    name: "student",
    type: "query",
    path: "me.availableStudents",
    query: StudentsWithLocation,
    label: t("student.name"),
    text: "full_name",
    validation: {
      required,
    },
    bind: {
      readonly: props.enrollment_id != null || "student" in props.baseItem,
    },
  });

  const Course = getField({
    name: "course",
    type: "query",
    path: "location.courses",
    query: LocationCourses,
    variables: (model: BaseModel) => ({ location_id: model.student?.location.id }),
    skip: (model: BaseModel) => !model.student,
    label: t("course.name"),
    validation: {
      required,
    },
  });

  const CourseFrequency = getField({
    name: "courseFrequency",
    type: "query",
    path: "courseFrequencies",
    text: "description",
    validation: {
      required,
    },
    query: CourseFrequencies,
    variables: (model) => ({ course_id: model.course?.id }),
    skip: (model) => !model.course,
    label: t("courseFrequency.name"),
  });

  const StudyClass = getField({
    name: "studyClass",
    type: "query",
    path: "course.studyClasses",
    text: "name",
    validation: {
      required,
    },
    query: CourseStudyClasses,
    variables: (model) => ({ course_id: model.course?.id }),
    skip: (model) => !model.course,
    label: t("studyClass.name"),
  });

  const Teacher = getField({
    name: "teacher",
    path: "studyClass.teachers",
    type: "query",
    query: StudyClassAvailableTeachers,
    variables: (model) => ({ study_class_id: model.studyClass?.id }),
    skip: (model) => !model.studyClass,
    label: t("teacher.name"),
    text: "name",
    validation: {
      required,
    },
  });

  const StartTimestamp: Field = {
    type: "datetime",
    name: "start_timestamp",
    isVisible: ({ courseFrequency }) => courseFrequency && !courseFrequency.start_timestamp,
    validation: {
      required,
    },
  };

  return {
    Students,
    Course,
    StudyClass,
    CourseFrequency,
    Teacher,
    StartTimestamp,
  };
};
