<template>
  <base-form
    :model="modelName"
    :fields="fields"
    :loading="loading || parentLoading"
    :defaultValue="baseItem"
    :currentValue="model"
    :saving="saving"
    :duplicating="duplicating"
    :graphQLErrors="graphQLErrors"
    ref="form"
    v-model="newValue"
    class="overflow-hidden"
    @save="onSave"
    @cancel="$emit('cancel')"
  >
    <template #top>
      <v-list class="px-4" v-if="mutationErrors.length">
        <template v-for="(error, i) in mutationErrors" :key="i">
          <v-alert density="compact" closable type="error" class="mb-0" :title="error" />
        </template>
      </v-list>
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </base-form>
</template>

<script
  setup
  lang="ts"
  generic="
    Q extends BaseModel,
    QP extends { id: string },
    C extends BaseModel,
    CP extends { input: any },
    U extends BaseModel,
    UP extends { id: string; input: any }
  "
>
import { Ref, ref, computed } from "vue";
import { BaseFormInterface, BaseModel, Field } from "@/bytenest-cli/definitions";
import BaseForm from "@/bytenest-cli/form/BaseForm.vue";
import { useFormMutations } from "@/bytenest-cli/composable/formMutations";
import { useQuery } from "@vue/apollo-composable";
import ucFirst from "@/bytenest-cli/utils/ucFirst";
import type { TypedDocumentNode as DocumentNode } from "@apollo/client";
import { Exact } from "../../gql/graphql";
import { GraphQLError } from "graphql";

interface IModelFormProps {
  modelName: string;
  modelId?: string;
  fields: Field[];
  query?: DocumentNode<Q, Exact<QP>>;
  createMutation: DocumentNode<C, Exact<CP>>;
  updateMutation?: DocumentNode<U, Exact<UP>>;
  baseItem?: BaseModel;
  duplicating?: boolean;
  preprocessor?: (input: BaseModel) => BaseModel;
  preparer?: (input: BaseModel) => BaseModel;
  parentLoading?: boolean;
  extraVariables?: Record<string, any>;
  errorHandlers?: { [key: string]: (error: GraphQLError) => string };
}

const props = withDefaults(defineProps<IModelFormProps>(), {
  baseItem: () => ({}),
  preprocessor: dummyTransformer,
  preparer: dummyTransformer,
  errorHandlers: () => ({}),
  extraVariables: () => ({}),
});

const newValue: BaseModel = ref({ password: "" });
const graphQLErrors = ref<GraphQLError[]>([]);

const form: Ref<BaseFormInterface | null> = ref(null);

const { updateModel, createModel, saving } = useFormMutations({
  model: ucFirst(props.modelName),
  createMutation: props.createMutation,
  updateMutation: props.updateMutation,
  preprocessor: props.preprocessor,
});

const emit = defineEmits<{
  created: [payload: C];
  updated: [payload: U];
  "update:queryModel": [payload: BaseModel];
  cancel: [];
}>();

const mutationErrors = ref<string[]>([]);

function filterHandledErrors(errors: GraphQLError[]) {
  const handledErrors = Object.keys(props.errorHandlers);

  mutationErrors.value = errors
    .filter((error) => handledErrors.includes(error.message))
    .map((error) => props.errorHandlers[error.message](error));

  return errors.filter((error) => !handledErrors.includes(error.message));
}

async function update() {
  if (!props.modelId) return;

  const payload = await updateModel(props.modelId, newValue.value, props.extraVariables);
  if (payload.data) {
    emit("updated", payload.data);
    form.value?.resetInfo();
  }
  if (payload.errors) graphQLErrors.value = filterHandledErrors(payload.errors);
}

async function create() {
  const payload = await createModel(newValue.value, props.extraVariables);
  if (payload.data) {
    emit("created", payload.data);
    form.value?.resetInfo();
  }
  if (payload.errors) graphQLErrors.value = filterHandledErrors(payload.errors);
}

const enabled = computed(() => !!props.modelId && !props.parentLoading);

const variables = computed<QP>(() => ({ id: props.modelId, ...props.extraVariables }) as QP);

const { result, loading, onResult } =
  props.modelId && props.query
    ? useQuery(props.query, variables, { enabled })
    : { result: ref(null), loading: ref(false), onResult: () => null };

const model = computed(() => (result.value ? props.preparer({ ...result.value[props.modelName] }) : {}));

onResult(() => emit("update:queryModel", model.value));

function onSave() {
  if (props.modelId && !props.duplicating) update();
  else create();
}
</script>

<script lang="ts">
function dummyTransformer(model: BaseModel) {
  return model;
}
</script>
