<template>
  <v-app>
    <v-app-bar v-if="logged" color="primary" border="b" app :elevation="0">
      <v-app-bar-nav-icon icon="mdi-menu" @click="drawer = !drawer" v-if="smAndDown"></v-app-bar-nav-icon>
      <v-app-bar-title class="text-uppercase flex-grow-1 flex-shrink-0">MSM {{ clientName }}</v-app-bar-title>
      <v-btn
        icon="mdi-transfer-up"
        size="small"
        variant="outlined"
        class="mr-2"
        @click="update"
        :loading="updating"
        v-if="needRefresh"
      ></v-btn>
      <language-switcher></language-switcher>
    </v-app-bar>
    <v-navigation-drawer
      width="270"
      color="grey-lighten-3"
      app
      :modelValue="drawer"
      :permanent="permanent"
      @update:modelValue="drawer = $event"
      v-if="logged"
    >
      <v-list :lines="false" nav class="bg-transparent">
        <v-list-subheader>Navigazione</v-list-subheader>
        <div v-for="(item, i) in panels" :key="i">
          <v-list-group v-if="'container' in item" :prepend-icon="item.icon">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :title="item.text"></v-list-item>
            </template>
            <v-list-item
              v-for="(subitem, i) in item.pages"
              :key="i"
              link
              :to="subitem.page"
              :prepend-icon="subitem.icon"
            >
              <v-list-item-title class="font-weight-regular font-italic">{{ subitem.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-subheader v-else-if="'subheader' in item">{{ item.text }}</v-list-subheader>
          <v-list-item v-else :to="item.page" :prepend-icon="item.icon">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </div>

        <div v-if="me?.profile?.__typename == 'Administrator'">
          <v-divider></v-divider>
          <v-list-subheader>Amministrazione</v-list-subheader>
          <v-list-item :to="{ name: 'Courses' }" prepend-icon="mdi-book">
            <v-list-item-title>{{ $t("course.name", 2) }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'AcademicYears' }" prepend-icon="mdi-calendar">
            <v-list-item-title>{{ $t("academicYear.name", 2) }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'HolidayRanges' }" prepend-icon="mdi-blinds">
            <v-list-item-title>{{ $t("holidayRange.name", 2) }}</v-list-item-title>
          </v-list-item>
        </div>

        <v-divider></v-divider>
        <v-list-subheader>Profilo</v-list-subheader>
        <v-list-item :to="{ name: 'Profile' }" prepend-icon="mdi-account">
          <v-list-item-title>{{ $t("page.profile") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout" prepend-icon="mdi-logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <div v-if="me" class="text-center text-body-2 my-4">Utente: {{ me.full_name }}</div>
      <div class="text-center text-body-2 font-weight-thin">
        Developed by <br />
        Bytenest di Roberto Del Ben
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useApolloClient, useQuery } from "@vue/apollo-composable";
import { graphql } from "@/gql/gql";
import { computed, ref, watch, provide } from "vue";
import { useRouter } from "vue-router";
import { onLogout } from "./plugins/apollo";
import { isLoggedIn } from "./utils/login";
import { useDisplay } from "vuetify";
import { useI18n } from "vue-i18n";
import LanguageSwitcher from "./bytenest-cli/components/LanguageSwitcher.vue";
import { IPage } from "@bytenest/definitions";
import useServiceWorker from "@bytenest/composable/useServiceWorker";

const { update, updating, needRefresh } = useServiceWorker();

const { t } = useI18n();

const router = useRouter();
const { client: apolloClient } = useApolloClient();

const clientName = ref(import.meta.env.VITE_CLIENT_NAME);

const panels = computed<IPage[]>(() => {
  if (!me.value) return [];
  if (me.value.profile?.__typename == "Teacher") {
    const teacherPanels = [
      { page: { name: "Dashboard" }, text: "Dashboard", icon: "mdi-view-dashboard" },
      { page: { name: "EnrollmentRequests" }, text: "Richieste di iscrizione", icon: "mdi-format-list-bulleted" },
      { page: { name: "Groups" }, text: "Classi", icon: "mdi-google-classroom" },
      // { page: { name: "CoursesManagement" }, text: "Gestione corsi", icon: "mdi-book-music" },
      { subheader: true, text: "Calendario" },
      { page: { name: "WeekPlanner" }, text: "Pianifica settimana", icon: "mdi-calendar-week" },
      { page: { name: "Calendar" }, text: "Calendario", icon: "mdi-calendar" },
      { page: { name: "Recovers" }, text: "Recuperi", icon: "mdi-backup-restore" },
      { page: { name: "Hours" }, text: "Monteore", icon: "mdi-clipboard-text" },
    ];
    if (certificates_enabled.value)
      teacherPanels.push({ page: { name: "Certificates" }, text: "Attestati", icon: "mdi-certificate" });

    return teacherPanels;
  }

  if (me.value.profile?.__typename == "Customer")
    return [
      { page: { name: "Dashboard" }, text: t("page.dashboard"), icon: "mdi-view-dashboard" },
      { page: { name: "MyStudents" }, text: t("page.student_profiles"), icon: "mdi-format-list-bulleted" },
      ...(clientName.value != "MANIAGO"
        ? [{ page: { name: "MyEnrollmentsInvoicing" }, text: t("invoice.name", 2), icon: "mdi-cash" }]
        : []),
      { page: { name: "NewEnrollment" }, text: t("page.subscription", 2) + " 2024/2025", icon: "mdi-clipboard-text" },
    ];
  if (me.value.profile?.__typename == "Administrator")
    return [
      { page: { name: "Dashboard" }, text: "Dashboard", icon: "mdi-view-dashboard" },
      {
        container: true,
        text: "Utenti",
        icon: "mdi-account-multiple",
        pages: [
          { page: { name: "Users", params: { type: "customers" } }, text: "Clienti", icon: "" },
          { page: { name: "Users", params: { type: "teachers" } }, text: t("teacher.name", 2), icon: "" },
          { page: { name: "Users", params: { type: "administrators" } }, text: "Amministratori", icon: "" },
        ],
      },
      { page: { name: "Students" }, text: "Profili studente", icon: "mdi-format-list-bulleted" },
      {
        container: true,
        text: t("page.subscription", 2) + " 2024/2025",
        icon: "mdi-clipboard-text",
        pages: [
          {
            page: { name: "Enrollments", params: { status: "pending" } },
            text: t("enrollment.status.PENDING"),
            icon: "",
          },
          {
            page: { name: "Enrollments", params: { status: "rejected" } },
            text: t("enrollment.status.REJECTED"),
            icon: "",
          },
          {
            page: { name: "Enrollments", params: { status: "approved" } },
            text: t("enrollment.status.APPROVED"),
            icon: "",
          },
          {
            page: { name: "Enrollments", params: { status: "closed" } },
            text: t("enrollment.status.CLOSED"),
            icon: "",
          },
        ],
      },
      { page: { name: "EnrollmentsInvoicing" }, text: t("invoice.name", 2), icon: "mdi-cash" },
      { page: { name: "Exports" }, text: t("export", 2), icon: "mdi-download" },
    ];
  return [];
});

const { smAndDown } = useDisplay();
const drawer = ref(!smAndDown.value);
const permanent = ref(!smAndDown.value);

watch(smAndDown, (value) => {
  drawer.value = !value;
  permanent.value = !value;
});

const logged = ref(isLoggedIn());

function logout() {
  onLogout(apolloClient).then(() => router.push({ name: "Login" }));
  logged.value = false;
}

const ME_QUERY = graphql(`
  query Me {
    certificates_enabled
    me {
      id
      full_name
      profile {
        __typename
        ... on Customer {
          id
        }
        ... on Teacher {
          id
        }
        ... on Administrator {
          id
        }
      }
    }
  }
`);

if (logged.value) {
  apolloClient.query({ query: ME_QUERY }).then(({ data }) => {
    if (data.me == null) logout();
    return;
  });
}

const { result } = useQuery(ME_QUERY);
const me = computed(() => result.value?.me ?? undefined);
const certificates_enabled = computed(() => result.value?.certificates_enabled ?? false);

function loggedIn(redirect: string | null = null): void {
  logged.value = true;

  if (redirect) {
    router.push(redirect);
    return;
  }

  if (panels.value == null) return;

  if ("container" in panels.value[0]) {
    router.push(panels.value[0].pages[0].page);
  } else if ("page" in panels.value[0]) {
    router.push(panels.value[0].page);
  }
}

provide("loggedIn", loggedIn);
</script>

<style lang="scss">
@media print {
  body * {
    visibility: hidden;
  }
  .printable,
  .printable * {
    visibility: visible;
  }
  .printable {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100vw;
  }
}
</style>
