import { useRegisterSW } from "virtual:pwa-register/vue";
import { ref } from "vue";

export default function useServiceWorker() {
  const { updateServiceWorker, needRefresh } = useRegisterSW({
    onRegistered(r) {
      if (!r) return;

      setInterval(() => r.update(), 60 * 1000);
    },
  });

  const updating = ref(false);

  function update() {
    updating.value = true;
    updateServiceWorker(true)?.catch(() => {
      updating.value = false;
    });
  }

  return { update, updating, needRefresh };
}
